.introjs-tooltipbuttons .introjs-button{
    text-transform: uppercase;
    color: #fff;
    border-radius: 20px;
    outline: none;
    border: none;
    font-family: "Satoshi", "sans-serif" !important;
    text-shadow: none;
}

.introjs-tooltipbuttons .introjs-prevbutton {
    color: black;
}

.introjs-tooltipbuttons .introjs-nextbutton {
    background-color: rgb(79 70 229);
}


.introjs-tooltip  .introjs-tooltiptext {
    font-family: "Satoshi", "sans-serif" !important;
    letter-spacing: 1.4px;
    line-height: 20px;
    font-size: 14px;
}