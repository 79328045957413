	html {
		scroll-behavior: smooth;
	}

	body {
		font-family: 'Roboto', 'Montserrat', sans-serif !important;
		margin-top: 0px !important;
	}

	* {
		box-sizing: border-box;
	}

/* 
*::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

*::-webkit-scrollbar {
	width: 12px;
	background-color: #F5F5F5;
}


*::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
} */



	/* XLScoreInsights  */
	.carousel__dot-group{
		/* background: #000; */
		display: flex;
		justify-content: center;
		align-items: center;
		height: 20px;
		gap: 10px;
	}
	
	.carousel__dot-group > .carousel__dot {
		background: gray;
		width: 20px;
		height: 20px;
		border-radius: 50%;
	}
	
	.carousel__dot-group > .carousel__dot--selected {
		background: black;
	}


	/* mobile slider    */
	.mobile-slider svg {
		height: 225px;
	}




	@media only screen and (max-width: 800px){
		::-webkit-scrollbar {
			-webkit-appearance: none;
		}
		
		::-webkit-scrollbar:vertical {
			width: 7px;
		}
		
		::-webkit-scrollbar:horizontal {
			height: 7px;
		}
		
		::-webkit-scrollbar-thumb {
			background-color: rgba(0, 0, 0, .5);
			border-radius: 10px;
			border: 1px solid #ffffff;
		}
		
		::-webkit-scrollbar-track {
			border-radius: 10px;
			background-color: #ffffff;
		}
	}


